var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticStyle: { width: "100%" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "10", md: "6", lg: "5" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-input", {
                                attrs: { placeholder: "Search" },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.refresh.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.query,
                                  callback: function ($$v) {
                                    _vm.query = $$v
                                  },
                                  expression: "query",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { variant: "success" },
                                      on: { click: _vm.refresh },
                                    },
                                    [_vm._v("Go")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "1",
                            offset: "1",
                            "offset-md": "5",
                            "offset-lg": "6",
                          },
                        },
                        [
                          _vm.addModal
                            ? _c(
                                "b-btn",
                                {
                                  staticClass: "float-right",
                                  attrs: { variant: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show(_vm.addModal)
                                    },
                                  },
                                },
                                [_c("fa-icon", { attrs: { icon: "plus" } })],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "center", "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.count,
                              "per-page": _vm.itemsPerPage,
                              "aria-controls": "oem-user-table",
                            },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-container",
        [
          _c("b-table", {
            attrs: {
              id: "oem-user-table",
              items: _vm.items,
              fields: _vm.fields,
              busy: _vm.loading,
              responsive: "",
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-secondary my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle mr-2" }),
                        _c("strong", [_vm._v("Loading...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _c(
                      "b-btn",
                      {
                        attrs: { variant: "secondary" },
                        on: {
                          click: function ($event) {
                            return _vm.editSelected(data.value)
                          },
                        },
                      },
                      [_c("fa-icon", { attrs: { icon: "edit" } })],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }